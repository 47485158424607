import "jquery"
import "jquery-lazy"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-select"
import "bootstrap-select/dist/css/bootstrap-select.min.css"
import "jquery-debounce-throttle"
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

import 'animate.css';
import "../css/custom.css"

$(window).on('beforeunload', function () {
    $(window).scrollTop(0);
});

$(window).on('load', function () {

    "use strict";

    $('html').addClass("ready");

    $('a[href^="http"]').attr('target', '_blank');

    setTimeout(function () {
        $('#section-banner').css("transition", 'unset');
    }, 7000);
    $(".navbar-nav li a").on('click', function () {
        $(".navbar-collapse").collapse('hide');
    });

    $(function () {
        $(document).ready(function () {
            $('.lazy').Lazy({
                scrollDirection: 'vertical',
                effect: 'fadeIn',
                visibleOnly: true
            });
            $('.lazy-carousel').on("slide.bs.carousel", function (ev) {
                let lazy;
                lazy = $(ev.relatedTarget).find("img[data-src]");
                lazy.each(function () {
                    $(this).attr("src", $(this).data('src'));
                });
                lazy.removeAttr("data-src");
            });
            $('.story-modal.modal').on('shown.bs.modal', function () {
                // wait for fade effect to complete
                setTimeout(function () {
                }, 2500);

                let lazy = $(this).find("img.lazy");
                lazy.each(function () {
                    $(this).attr("src", $(this).data('src'));
                });
                lazy.removeAttr("data-src");
            });
        });
    });

    $("#btn-goto-das").click(function () {
        $('html, body').animate({
            scrollTop: $("#section-das").offset().top - $("#eu-navbar").height()
        }, 1200);
    });


    $(window).scroll($.throttle(function () {


        if ($(this).scrollTop() > 25) {
            $('.navbar-fixed-top').addClass('opaque');
        } else {
            $('.navbar-fixed-top').removeClass('opaque');
        }

        $(".navbar-social li i").each(function () {
            let socialIcon = this;
            let socialRect = this.getBoundingClientRect();
            $(".section").each(function () {
                let sectionRect = this.getBoundingClientRect();
                if (socialRect.y > sectionRect.y && socialRect.y < sectionRect.y + sectionRect.height) {
                    $(socialIcon).css("color", $(this).data("social-icon-color"))
                }
            });
        });

        let bannerRect = $('#section-banner')[0].getBoundingClientRect();
        if (bannerRect.top + bannerRect.height - window.innerHeight < 0) {
            $('.powered-by-eurid').css('position', 'absolute');
        } else {
            $('.powered-by-eurid').css('position', 'fixed');
        }

        let expressIdentityRect = $('#section-express-identity')[0].getBoundingClientRect();

        if (expressIdentityRect.top + expressIdentityRect.height > 0) {
            let bgEuTop = (expressIdentityRect.top / window.innerHeight) * 30 + 10;
            let bgHandsTop = (expressIdentityRect.top / window.innerHeight) * 15 + 70;
            if (window.innerWidth < 1000) bgHandsTop += 10;
            $('#section-express-identity').css('backgroundPosition', '50% ' + bgEuTop + 'vh, 50% ' + bgHandsTop + 'vh');
        }
    }, 20));

    $(window).resize(function () {
        $(window).trigger('scroll');

        if (window.innerWidth >= 1000) {
            $("#section-security").addClass('expanded');
        } else {
            $("#section-security").removeClass('expanded');
        }


        if (window.swiper) {
            let slidesPerView = window.innerWidth > 768 ? 3 : 1;
            if (window.swiper.params.slidesPerView != slidesPerView) {
                window.swiper.params.slidesPerView = slidesPerView;
                window.swiper.params.slidesPerGroup = slidesPerView;
                window.swiper.update();
            }
        }

    });

    $(window).trigger('resize');

    $('.expand-tabs').click(function () {
            let $this = $(this);
            if (window.innerWidth < 1000 && $this.data('state') !== 'transition') {
                $this.data('state', 'transition');
                let columnRight = window.innerWidth - 82 + "px";
                $("#section-security #v-pills-tabContent-parent").css('width', columnRight);
                $("#section-security").toggleClass('expanded');
                setTimeout(function () {
                    $("#section-security #v-pills-tabContent-parent").removeAttr('style');
                    $this.data('state', 'completed');
                }, 500);
            }
        }
    );

    window.loadModalContent = function (id, youtube_id, url) {
        $.ajax({
            type: 'GET',
            url: url + '?uuid=' + id,
            success: function (data) {
                let modal_story = $('#story-modal .modal-content');
                modal_story.attr('data-youtube-id', youtube_id);
                modal_story.html(data);
                $("#youtube_video_" + id).attr('src', "https://www.youtube-nocookie.com/embed?loop=1&autoplay=1&playlist=" + youtube_id);
            }
        });
    }

    $('.form-check-domain').on('submit', function (evt) {
        evt.preventDefault();

        let form = $(this);
        let dasResult = form.find('.das-result');
        let domain = form.find('input[name=domain]').val() || '';
        dasResult.fadeOut();
        dasResult.removeClass();
        dasResult.addClass("das-result alert animate__animated form-group");

        $('div[data-result-type]').hide();


        if (!domain) {
            $('div[data-result-type=no-entry]').show();
            return;
        }

        $.get(form.attr('action') + '?domain=' + encodeURIComponent(domain)).done(function (data) {
            $('div[data-result-type=' + data.status + '] strong').text(domain);
            let whoisLink = $('div[data-result-type=' + data.status + '] a');
            whoisLink.text(domain);
            let href = whoisLink.attr('href') + domain;
            whoisLink.attr('href', href);
            $('div[data-result-type=' + data.status + ']').show();
            _paq.push(['trackEvent', 'Verification', 'DAS', domain + ' is ' + data.status.toLowerCase()]);
        }).fail(
            function () {
                $('div[data-result-type=error]').show();
            }
        );
    });

    if (/Android|webOS|BlackBerry/i.test(navigator.userAgent)) {
        $(".selectpicker").selectpicker('mobile');
    }

    $('#language-picker-form select').change(function () {
        $('#language-picker-form').submit();
    })
    $("#story-modal").on('hide.bs.modal', function () {
        $(this).find('iframe').attr('src', '');
    });


    let hash = window.location.hash.substr(0);
    if (hash) {
        if ($('#link-img-video-' + hash).length) {
            $('#link-img-video-' + hash).click();
        }
    }

    let slidesPerView = window.innerWidth > 768 ? 3 : 1;

    window.swiper = new Swiper('.swiper-container', {
        slidesPerView: slidesPerView,
        slidesPerGroup: slidesPerView,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'fraction'
        },
        mousewheel: true,
        keyboard: true,
    });

    new Swiper('.registrar-swiper', {
        autoHeight: true,
        lazyPreloadPrevNext: 1,
        slidesPerView: 1,
        spaceBetween: 40,
        className: "mb-5 mt-2",
        enabled: true,
        autoplay: {delay: 5000}
    })
});
$(function () {
    $('#banner a').click(function () {
        $("html, body").animate({scrollTop: window.innerHeight}, 1000);
    });
})

